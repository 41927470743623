<template>
    <div class="h-full w-full px-4 flex flex-col items-center justify-center">
        <h1 class="w-full mb-2 text-center text-2xl">{{ $t('auth.title_thank_you') }}</h1>
        <div class="w-full ">
            <template v-if="!saveForm.isInvite">
                <p class="text-lg text-center mb-8">
                    {{ $t('auth.message_registration_pending') }}
                </p>
            </template>
            <div 
                class="w-full button_enter"
                :class="saveForm.isInvite && 'invite'">
                <a-button
                    class="w-full"
                    size="large"
                    @click="closeDrawer"
                    type="primary">
                    {{ $t('auth.button_login') }}
                </a-button>
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    props: {
        regForm: {
            type: Object,
            default: () => {}
        },
        setRegForm: {
            type: Function,
            default: () => {}
        },
        saveForm: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            deadline: this.$moment().add(2, 'minutes'),
            countdownSubmit: false,
            loading: false,
            codeLoader: false,
        }
    },
    methods: {
        closeDrawer() {
            eventBus.$emit('close_registration')
        }
    }
}
</script>

<style lang="scss" scoped>
.button_enter {
    max-width: 500px;
    margin: 0 auto;
}
.button_enter.invite {
    margin-top: 2rem;
}
</style>